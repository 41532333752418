<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Notifications'">
          <template v-slot:preview>
            <div class="notification-dropdown">
              <!--              <div class="notification-header">-->
              <!--                <span class="heading">Notifications</span>-->
              <!--              </div>-->
              <div class="notification-body">
                <ul>
                  <li class="text-center font-weight-bold text-heading">
                    Today
                  </li>
                  <li
                    class="unread-notification"
                    v-for="notification in notificationList"
                    :key="notification.order_uuid"
                  >
                    <div>
                      <a href="" class="d-flex align-items-start">
                        <img
                          src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg"
                          alt=""
                        />
                        <div>
                          <h5 class="mb-0 font-weight-bold text-orange">Abc</h5>
                          <p class="mb-0">
                            {{ notification.message }}
                          </p>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="text-center font-weight-bold text-heading"></li>
                  <li class="read-notification">
                    <div>
                      <a href="" class="d-flex align-items-start">
                        <img
                          src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg"
                          alt=""
                        />
                        <div>
                          <h5 class="mb-0 font-weight-bold text-orange">Abc</h5>
                          <p class="mb-0"></p>
                        </div>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <!--              <div class="notification-footer notification-header">-->
              <!--                <router-link to="/notificationslist" class="text-orange"><span>See All</span></router-link>-->
              <!--              </div>-->
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
// import ApiService from "@/core/services/api.service";
// import $ from "jquery";
import axios from "axios";
import helpers from "../../helpers";

export default {
  data() {
    return {
      notificationList: [],
      restaurantUUID: JSON.parse(localStorage.getItem("currentUser")).restaurant
        .uuid
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getAllNotifications() {
      axios
        .get(
          this.$path + "/admin/restaurant/notifications/" + this.restaurantUUID
        )
        .then(({ data }) => {
          this.notificationList = data.data.notifications;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    }
  },
  mounted() {
    this.getAllNotifications();
  }
};
</script>

<style scoped>
/*.loading-spinner {*/
/*  display: none;*/
/*}*/
.text-heading {
  color: #747f8b;
}
.notification-wrapper {
  margin-right: 30px;
}
.notification-wrapper i {
  font-size: 24px;
  cursor: pointer;
}
.notification-wrapper .badge-primary {
  background-color: #fd683e;
}
.notification-wrapper .notification-count {
  position: absolute;
  top: -5px;
  right: -15px;
}
.notification-dropdown {
  position: relative;
  /*display: none;*/
  z-index: 1;
  left: 0px;
  top: 0px;
  min-height: 10px;
  min-width: 10px;
  width: 100%;
}
.notification-wrapper .notification-header {
  background: #fff;
  padding: 15px;
  position: relative;
  text-align: center;
  color: #fd683e;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  -moz-border-image: -moz-linear-gradient(right, white, #cedae0, #cedae0, white)
    1 20%;
  -o-border-image: -o-linear-gradient(right, white, #cedae0, #cedae0, white) 1
    20%;
  border-image: linear-gradient(
      to right,
      white 0%,
      #cedae0 40%,
      #cedae0 60%,
      white 100%
    )
    1 20%;
  box-shadow: 0px 2px 10px -2px #cedae0;
}
.notification-body {
  /*max-height: 292px;*/
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.notification-body li p {
  color: #9b9b9b;
}
.notification-body li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}
.notification-body li a {
  color: #333;
  cursor: default;
  pointer-events: none;
}
.notification-body li.unread-notification {
  background-color: #def2f7;
}
.notification-body li.unread-notification p {
  color: #333;
}
.notification-body li.read-notification {
  background-color: #fff;
}
.notification-body li img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.notification-footer.notification-header {
  border-radius: 0 0 10px 10px;
  border-bottom: 0;
}
</style>
